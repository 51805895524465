import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout";
import PageHero from "../components/PageHero/PageHero";
import Seo from "../components/seo";
import Positions from "../components/Positions/Positions";

const sokJobb = ({ data: { page } }) => {
  const { title, text, image } = page.header_twocol.header;
  const positions = page.jobb.ledigaTjanster;
  return (
    <Layout>
      <Seo title={"Sök jobb"} description={text} />
      <PageHero src={image?.localFile} title={title} />

      <section
        className="min-h-[50vh] border-t border-opacity-10 border-lightest"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        <div className="container max-w-3xl pt-12">
          <p className="mb-0">{text}</p>
        </div>
        <div className="container max-w-3xl py-12">
          <Positions positions={positions}></Positions>
        </div>
      </section>
    </Layout>
  );
};

export default sokJobb;

export const pageQuery = graphql`
  query JobPage {
    page: wpPage(title: { eq: "Sök jobb" }) {
      id
      header_twocol {
        header {
          text
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      jobb {
        ledigaTjanster {
          arbetsplats
          beskrivning
          fieldGroupName
          ort
          sistaAnsokningsdag
          tidsomfattning
          tjanst
          typAvAnstLlning
        }
      }
    }
  }
`;
