import { motion } from 'framer-motion'
import React, { useState } from 'react'
import Position from './Position'
import './Positions.scss'

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      type: 'tween',
    },
  },
}

const Positions = ({ positions }) => {
  const [expanded, setExpanded] = useState(null)
  const expandPosition = (index) => {
    if (index === expanded) {
      setExpanded(null)
      return
    }
    setExpanded(index)
  }
  return (
    <>
      {!positions?.length && <p>Just nu finns det inga tjänster!</p>}
      <motion.ul
        variants={container}
        initial="hidden"
        animate="show"
        className={`positions`}
      >
        {positions?.length &&
          positions.map((item, index) => (
            <Position
              key={index}
              expand={() => expandPosition(index)}
              expanded={expanded === index}
              item={item}
            />
          ))}
      </motion.ul>
    </>
  )
}

export default Positions
