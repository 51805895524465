import { motion } from 'framer-motion'
import React from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'
import parse from 'html-react-parser'
import './Position.scss'

const itemMotion = {
  hidden: { opacity: 0, y: -10 },
  show: { opacity: 1, y: 0 },
}

const Position = ({ item, expand, expanded }) => {
  return (
    <motion.li
      variants={itemMotion}
      className={`position ${expanded ? 'expanded' : ''}`}
    >
      <div className="header">
        {item?.tjanst && <p className="tjanst ">{item.tjanst}</p>}
        <button className="expand-btn" onClick={expand}>
          Läs mer
          <RiArrowDownSLine
            size={'1.75rem'}
            className={`${expanded ? 'rotate-180' : ''}`}
          />
        </button>
      </div>
      <motion.div
        initial="collapsed"
        animate={expanded ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        className="body"
      >
        <div className="inner">
          <div className="left">
            <p>{parse(item.beskrivning)}</p>
          </div>
          <div className="right">
            <ul className="flex flex-col">
              <li>
                <p className="font-bold text-white mb-0">Ansökan</p>
                <p className="opacity-70 text-ua-green-light font-bold">
                  {'jobba@uapadel.se'}
                </p>
              </li>
              {item.arbetsplats && (
                <li>
                  <p className="font-bold text-white mb-0">Arbetsplats</p>
                  <p className="opacity-70">{item.arbetsplats}</p>
                </li>
              )}
              {item.ort && (
                <li>
                  <p className="font-bold text-white mb-0">Ort</p>
                  <p className="opacity-70">{item.ort}</p>
                </li>
              )}
              {item.tidsomfattning && (
                <li>
                  <p className="font-bold text-white mb-0">Tidsomfattning</p>
                  <p className="opacity-70">{item.tidsomfattning}</p>
                </li>
              )}
              {item.typAvAnstLlning && (
                <li>
                  <p className="font-bold text-white mb-0">
                    Typ av anställning
                  </p>
                  <p className="opacity-70">{item.typAvAnstLlning}</p>
                </li>
              )}
              {item.sistaAnsokningsdag && (
                <li>
                  <p className="font-bold text-white mb-0">
                    Sista ansökningsdag
                  </p>
                  <p className="capitalize opacity-70">
                    {item.sistaAnsokningsdag}
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </motion.div>
    </motion.li>
  )
}

export default Position
